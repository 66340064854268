.settings {
  .content {
    margin-top: 1em;
  }

  .header {
    color: #ff6b00;
  }

  .button {
    font-size: 1.1em;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 4em;
    border-radius: unset;
    color: #191919;
  }

  .label {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
