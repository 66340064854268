@use './components/common/variables.scss';

.dark {
  background: #1a1a1a url('./components/assets/fruits.svg');
  color: #fff;

  .page-header {
    background: #1a1a1a url('./components/assets/fruits.svg');

    .back-button {
      background: #292929;
      color: variables.$light-brown;
    }

    .app-name span {
      color: #969696;
    }
  }

  .form-field .submit-button,
  .pagination .Mui-selected,
  .clear-all {
    background: variables.$dark-brown;
    color: variables.$white;

    &:hover {
      background: variables.$dark-brown;
    }
  }

  .bottom-nav {
    border-color: #3d3d3d50;

    .navigation-wrapper {
      background: #1a1a1a url('./components/assets/fruits.svg') repeat fixed;
    }
  }

  .toggle-form-button {
    background: #2f2f2f;
    border: 0.05em solid #ffffff97;
    color: #fff;
  }

  .lists,
  .trash {
    .item {
      background: #3e3e3e7d;
    }

    .unPurchased {
      border-color: #ffffff97;
    }

    .purchased {
      .name,
      .info {
        color: #66bb6a;
      }
    }

    .delete-button {
      color: #f88d8f;
    }

    .footer button {
      background: variables.$dark-brown;
      color: variables.$white;
    }
  }

  .popular {
    .search-field-wrapper {
      background: #262626;
      border-color: #ffffff61;

      &:hover {
        border: 0.05em solid variables.$light-red;
      }
    }

    .search-field,
    .search-button {
      color: #fff;
    }

    .content {
      background: #3939397d;
    }

    .item {
      border-color: #3c3c3c97;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .recipe-wrapper {
    h5 {
      color: variables.$light-red;
    }

    .recipe {
      background: #3434347d;
      border: 0.5px solid #ffffff97;
    }

    .info {
      color: #d7d7d7;
    }

    .read-more-button {
      color: #fff;
    }

    .delete-button {
      color: #f88d8f;
    }
  }

  .ingredients {
    h5 {
      color: variables.$light-red;
    }

    .item {
      background: #3e3e3e7d;
    }

    .footer .clear-all-button {
      background: variables.$dark-brown;
      color: #fff;

      &:hover {
        background: variables.$dark-brown;
      }
    }
  }

  .trash {
    .restore-button {
      color: #66bb6a;
    }

    .empty-trash-button {
      background: variables.$dark-brown;
      color: variables.$white;

      &:hover {
        background: variables.$dark-brown;
      }
    }
  }

  .recipe-form {
    background: #262626;

    .submit-button {
      background: variables.$dark-brown;
      color: variables.$white;

      &:hover {
        background: variables.$dark-brown;
      }
    }
  }

  .settings .button {
    color: #fff;
  }

  .privacy-policy p {
    background: #0000004d;
  }
}
