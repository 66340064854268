@use '../common/variables.scss';

.lists {
  .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    margin-top: 1.5em;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    border-radius: 0.5em;
    background: variables.$light-grey;
    width: 100%;
    box-sizing: border-box;
    height: 3.7em;
  }

  .unPurchased {
    border: 0.05em solid #000;
  }

  .left-column {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding-left: 0.7em;
  }

  .name {
    word-break: break-all;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .purchased {
    .name,
    .info {
      text-decoration: line-through;
      color: #038a58;
    }
  }

  .photo-wrapper {
    width: 2.2em;
    height: 2.2em;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .list-buttons-wrapper {
    display: flex;
    align-items: center;
  }

  .edit-button {
    transform: scale(1.1);
  }

  .delete-button {
    color: #f54c4c;
    transform: scale(1.2);
  }

  .select-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select-form {
    width: 60%;

    .label {
      z-index: 0;
    }
  }

  .total-price {
    font-size: 1.15em;
    color: variables.$light-brown;
    margin: 1em 0 1em;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    margin-bottom: 2em;

    button {
      background: variables.$orange;
      color: #000;
      text-transform: capitalize;
      font-size: 0.95em;
      transition: none;
      box-shadow: none;
    }
  }
}
