@use '../common/variables.scss';

.popular {
  .no-data-text {
    text-align: center;
  }

  .title {
    color: variables.$light-red;
    font-size: 1.1em;
  }

  .search-field-wrapper {
    display: flex;
    align-items: center;
    height: 2.8em;
    width: 100%;
    background: variables.$light-grey;
    border: 0.05em solid #000;
    border-radius: 0.3em;
  }

  .search-field {
    height: 2.5em;
    width: 100%;
    background: unset;
    border: unset;
    padding: 0 1.2em;
    font-size: 1.1em;

    &::placeholder {
      font-size: 1.1em;
    }

    &:hover {
      outline: unset;
    }
  }

  .content {
    margin-bottom: 4em;
    background: variables.$light-grey;
    border-radius: 0.5em;
  }

  .search-button {
    background: transparent;
    color: #000;
    box-shadow: none;

    &:hover {
      box-shadow: none;
      background: none;
    }
  }

  .item {
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.5fr;
    align-items: center;
    gap: 1em;
    padding: 0.8em 1em;
    border-bottom: 0.01px solid #0000001c;

    &:last-child {
      border-bottom: none;
    }
  }

  .photo-wrapper {
    width: 2.8em;
    height: 2.8em;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .add-to-list-button {
    border: unset;
    background: variables.$dark-cyan;
    color: variables.$white;
    font-size: 0.9em;
    border-radius: 0.3em;
    text-transform: capitalize;
    padding: 0.6em 0.5em;
  }

  .in-list {
    background: #494949;
  }

  .pagination {
    display: flex;
    justify-content: center;

    .Mui-selected {
      background: variables.$orange;

      &:hover {
        background: variables.$orange;
      }
    }
  }

  @media screen and (max-width: 402px) {
    .item {
      grid-template-columns: 0.3fr 1fr 0.9fr;
    }
  }

  @media screen and (max-width: 340px) {
    .item {
      grid-template-columns: 0.3fr 1fr;
      gap: 0.2em;
      padding: 0.2em 1em 1em;

      .add-to-list-button {
        grid-column: 1 / 3;
      }
    }
  }
}
