@use '../common/variables.scss';

.trash {
  padding: 0 0.5em;

  .empty-trash-info {
    display: block;
    line-height: 1.5em;
    font-size: 1.1em;
    text-align: center;
  }

  .left-column {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding-left: 0.8em;
  }

  .photo-wrapper {
    width: 2.2em;
    height: 2.2em;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .empty-trash-info {
      display: inline;
      text-align: left;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.3em;
    border-radius: 0.5em;
    background: variables.$light-grey;
    width: 100%;
    box-sizing: border-box;
    height: 3.7em;
  }

  .unPurchased {
    border: 0.05em solid #000;
  }

  .purchased {
    .name,
    .info {
      text-decoration: line-through;
      color: #038a58;
    }
  }

  .list-buttons-wrapper {
    display: flex;
    gap: 0.5em;
    align-items: center;
  }

  .restore-button {
    transform: scale(1.25);
    color: #038a58;
  }

  .delete-button {
    color: #f54c4c;
    transform: scale(1.3);
  }

  .empty-trash-button {
    text-transform: capitalize;
    font-size: 0.95em;
    width: 9em;
    background: variables.$orange;
    color: #000;
    box-shadow: none;

    &:hover {
      background: variables.$orange;
    }
  }
}
