@use './variables.scss';

.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  height: 4.5em;
  width: 100%;
  background: #f0f0f0 url('../assets/fruitsGrey.svg') repeat fixed;
  z-index: 1;

  .back-button {
    position: absolute;
    left: 1em;
    background: #e2e2e2ee;
    transition: none;
  }

  .app-name {
    text-transform: capitalize;
    font-size: 1.8em;

    span {
      color: #5e5e5e;
    }
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 1em;
  margin-top: 1.3em;
  animation: slide-down 0.5s ease-in-out;

  .item-info {
    display: flex;
    gap: 1em;
  }

  .label {
    z-index: 0;
  }

  .submit-button {
    background: variables.$orange;
    color: #000;
    text-transform: capitalize;
    font-size: 0.95em;
    transition: none;

    &:hover {
      background: variables.$orange;
    }
  }
}

.recipe-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em 1em;
  animation: slide-down 0.5s ease-in-out;
  background: variables.$light-grey;
  border-radius: 0.3em;

  .form-title {
    margin: 0;
    font-size: 1.1em;
  }

  .ingredients-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
  }

  .ingredients-error-message {
    color: #f73e3e;
    margin: 0;
    font-weight: 500;
  }

  button {
    color: #000;
    font-size: 0.95em;
    text-transform: capitalize;
    height: 2.2em;
    width: fit-content;
    transition: none;
  }

  .submit-button {
    width: 100%;
    height: 2.6em;
    background: variables.$orange;

    &:hover {
      background: variables.$orange;
    }
  }

  .add-field-button {
    background: variables.$dark-cyan;
    color: #fff;

    &:hover {
      background: variables.$dark-cyan;
    }
  }
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 0.1em solid #cacaca50;

  .navigation-wrapper {
    background: #f0f0f0 url('../assets/fruitsGrey.svg') repeat fixed;

    button {
      min-width: 0;
    }
  }
}

.modal {
  background: #10101090;

  .edit-modal .form-field {
    animation: none;
  }

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 3em 0;
    background: #fff;
    animation: fadeIn 0.3s ease-in-out;

    @media screen and (min-width: 700px) {
      width: 50%;
      margin: auto;
    }
  }

  .header {
    position: absolute;
    top: 1em;
    font-size: 1.5em;
    width: 100%;
    text-align: center;
  }

  .delete-modal {
    h5 {
      font-size: 1.5em;
      padding: 0 1em;
      margin: 0.3em 0 1em;
      word-wrap: break-word;
    }

    button {
      width: 6em;
      height: 2.5em;
    }
  }

  .edit-recipe-modal {
    .recipe-form {
      background: #fff;
      padding: 0 1.5em;
      animation: none;
    }

    h5 {
      margin-bottom: 1em;
    }
  }

  .close-button {
    position: absolute;
    left: 0.2em;
    background: unset;
    color: variables.$light-red;
  }

  .cancel-button {
    background: #aeaeae;
    color: #000;

    &:hover {
      background: #aeaeae;
    }
  }

  .update-button {
    background: variables.$orange;
    color: #000;
    width: 100%;
    font-size: 0.9em;
    height: 2.5em;
    text-transform: capitalize;

    &:hover {
      background: variables.$orange;
    }

    span {
      font-size: 1.1em;
    }
  }

  .delete-button {
    background: variables.$orange;
    color: #000;
    margin-left: 1em;

    &:hover {
      background: variables.$orange;
    }
  }

  @media screen and (max-width: 385px) {
    .delete-modal h5 {
      font-size: 1.3em;
    }
  }

  &.modal-dark {
    .box {
      background: #292929;
      color: #fff;
    }

    .cancel-button {
      background: #e8d9d9;

      &:hover {
        background: #c5b9b9;
      }
    }

    .update-button,
    .delete-button {
      background: variables.$dark-brown;
      color: variables.$white;

      &:hover {
        background: variables.$dark-brown;
      }
    }

    .edit-recipe-modal .recipe-form {
      background: #262626;
    }
  }
}

.empty-list-illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1em;
  padding: 0 1em;
  animation: fadeIn 0.5s;

  img {
    width: 15em;
    height: 15em;
    object-fit: cover;
  }

  p {
    font-size: 1em;
    letter-spacing: 0.15em;
    text-align: center;
  }
}

.toggle-form-button {
  background: #e2e2e2;
  border: 0.05em solid #000;
  border-radius: 0.5em;
  height: 3.5em;
  width: 5em;

  .icon {
    transition: all 0.2s ease-in-out;
  }

  &.cross {
    .icon {
      transform: rotate(-45deg);
    }
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
