@use '../common/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-wrapper {
  display: flex;
  gap: 1em;
  align-items: center;
}

.recipes {
  position: relative;
  margin-bottom: 3em;
  padding: 0.7em 0.5em 0;

  .form {
    margin: 1.5em 0;
  }

  .title {
    font-size: 1.3em;
    margin: 0;
    color: variables.$light-brown;
  }

  .empty-list-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  .recipe-wrapper {
    .toggle-form-button {
      position: absolute;
      right: 0.5em;
      top: 0;
    }

    .recipe {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: variables.$light-grey;
      border-radius: 0.5em;
      border: 0.05em solid #000;
      height: 3.5em;
      padding: 0 0.8em;
    }

    .info {
      color: #5e5e5e;
      font-weight: 500;
    }

    .read-more-button {
      color: #000;
      transform: scale(1.5);
      margin-right: 0.3em;
    }

    .delete-button {
      color: #f54c4c;
      transform: scale(1.2);
    }

    .recipe-list {
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin: 1.5em 0 1em;

      .recipe-info-wrapper {
        overflow: hidden;
      }

      .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .edit-button {
        transform: scale(1.2);
      }

      @media screen and (max-width: 385px) {
        .button-wrapper {
          gap: 0.2em;
        }
      }
    }
  }

  .clear-all {
    background: variables.$orange;
    color: #000;
    text-transform: capitalize;
    font-size: 0.93em;
    box-shadow: none;
    transition: none;

    &:hover {
      background: variables.$orange;
    }
  }
}

.ingredients {
  margin-bottom: 3em;
  padding: 0 0.5em;

  .header {
    margin-bottom: 1em;
  }

  h5 {
    font-size: 1.1em;
    color: variables.$light-brown;
    word-break: break-all;
    padding-right: 0.5em;
  }

  .list-wrapper {
    margin-top: 1em;

    .lists {
      display: flex;
      gap: 1em;
      flex-direction: column;
      margin: 0.8em 0 1.3em;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5em 0 1em;
      border-radius: 0.5em;
      background: variables.$light-grey;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    button {
      text-transform: capitalize;
      font-size: 0.95em;
      color: variables.$white;
    }

    .clear-all-button {
      background: variables.$orange;
      color: #000;
      box-shadow: none;
      transition: none;

      &:hover {
        background: variables.$orange;
      }
    }

    .add-to-list-button {
      background: variables.$dark-cyan;
      box-shadow: none;

      &.in-list-button {
        background: #494949;

        &:hover {
          background: #494949;
        }
      }

      &:hover {
        background: variables.$dark-cyan;
      }
    }
  }
}
