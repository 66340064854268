body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.app {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background: #f0f0f0 url('./components/assets/fruitsGrey.svg') repeat fixed;

  .pages {
    margin-top: 5em;
    padding: 0 1em 5em;
  }

  .privacy-policy {
    width: 50%;
    margin: auto;

    h5 {
      margin: 1em 0 0.2em;
      color: #ff6b00;
      font-weight: 500;
      text-transform: uppercase;
    }

    p {
      padding: 1em 2em;
      border-radius: 0.5em;
      text-align: justify;
      line-height: 1.8em;
      background: #ffffffc9;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

button:active {
  transform: translateY(0.1em);
}
