@use '../common/variables.scss';

.welcome {
  text-align: center;
  font-weight: 550;

  h4 {
    margin-top: 1em;
  }

  span {
    color: #969696;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .empty-list-message {
      p {
        font-size: 1.1em;
        font-family: 'Roboto';
        margin: 1.5em 0 1.5em;
      }
    }
  }

  .start-button {
    border: unset;
    border-radius: 1.5em;
    background: variables.$dark-cyan;
    color: variables.$white;
    padding: 0.8em 1.5em;
    font-weight: 600;
    font-size: 1em;
  }
}
